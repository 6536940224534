@tailwind base;
@tailwind components;
@tailwind utilities;



  a {
    @apply  text-blue-600 hover:text-blue-800
 }
 table *{
  border: 1px solid gray;
 }

 .sticky-div {
  position: sticky;
  top: 40px;
}